import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useForm, Controller } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DelIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails, Paper } from "@mui/material";
import {
  Stack,
  Autocomplete,
  TextField,
  Button,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Alert,
  Card,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { readCustomerById, readMachinesByCustomerId } from "../../../features/customers/actions";
import {
  addContactToLead,
  updateContact,
  updateLead,
  readAllCustomersByState,
  deleteContactById,
  deleteLocationById,
  addMachineToCustomers,
  addLocationsToCustomers,
  deleteMachineFromCustomer,
} from "../../../features/customers/actions";
import { readAsyncStorageValues } from "../../../features/common/actions";
import { QrCode as QRCodeIcon, Delete as DeleteIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import { LinearProgress } from "@mui/material";

const CustomerContact = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { machinesByCustomerId, customerDataById, loading } = useSelector((state) => state.customers);
  const [editCustomer, setEditCustomer] = useState(null);
  const { register: addEditMachineFormRegister, handleSubmit: addEditMachineHandleSubmit, reset: resetMachineInput } = useForm();
  const { register: addEditLocationFormRegister, handleSubmit: addEditLocationHandleSubmit, reset: resetLocationInput } = useForm();
  const { control, register: editFormRegister, handleSubmit: editHandleSubmit, reset: resetCustomer } = useForm();
  const { register: addEditContactFormRegister, handleSubmit: addEditHandleSubmit, reset } = useForm();
  const [addContact, setAddContact] = useState(null);
  const [machineLocation, setMachineLocation] = useState(null);
  const [selectedIndianState, setSelectedIndianState] = useState({ label: "Maharashtra (MH)", value: "MH" });
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [warrantyExpiryDate, setwarrantyExpiryDate] = useState(null);
  const [amcExpiryDate, setamcExpiryDate] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMachineModel, setDeleteMachineModel] = useState(null);
  const [deleteCustomerContact, setDeleteCustomerContact] = useState(null);
  const [deleteLocation, setDeleteLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchContactTerm, setSearchContactTerm] = useState("");
  const [searchLocationTerm, setSearchLocationTerm] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [hideProgress, setHideProgress] = useState(true);
  const [editContactModal, setEditContactModal] = useState(false);
  const [editMachineModal, setAddEditMachineModal] = useState(false);
  const [addEditLocationModal, setAddEditLocationModal] = useState(false);
  const [machineLocations, setMachineLocations] = useState([]);
  const [billingCharsRemaining, setBillingCharsRemaining] = useState(250);
  const [shippingCharsRemaining, setShippingCharsRemaining] = useState(250);
  const [isBillingAddressUpdated, setIsBillingAddressUpdated] = useState(false);
  const [isShippingAddressUpdated, setIsShippingAddressUpdated] = useState(false);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(readMachinesByCustomerId(id));
    dispatch(readCustomerById(id));
  }, [dispatch]);

  useEffect(() => {
    reset(null);
    setValidationErrors((prevState) => ({ ...prevState, contactEmail: null, contactNumber: null }));
  }, [editContactModal]);

  useEffect(() => {
    resetMachineInput(null);
    setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: null }));
  }, [editMachineModal]);

  useEffect(() => {
    resetLocationInput(null);
  }, [addEditLocationModal]);

  useEffect(() => {
    if (customerDataById && customerDataById?.info) {
      const customerInfo = customerDataById?.info;
      const customerLocations = customerInfo?.locations || [];
      setMachineLocations([...customerLocations].sort((a, b) => a.location.localeCompare(b.location)));
    }
  }, [customerDataById]);

  const { indianStateDropdownOptions, machineModels } = useSelector((state) => state.common);
  const handleBackClick = () => {
    navigate(-1);
  };
  const inputChangeHandler = (event) => {
    const mobileNumberRegex = /^[0-9+().\-\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const yearRegex = /^[1-9]\d{3}$/;
    let id = event.target.id;
    let value = event.target.value;
    if (id === "billingAddress") {
      setBillingCharsRemaining(250 - value.length);
      setIsBillingAddressUpdated(true);
    } else if (id === "shippingAddress") {
      setShippingCharsRemaining(250 - value.length);
      setIsShippingAddressUpdated(true);
    }
    if (id === "name") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, name: "Name is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, name: null }));
      }
    } else if (id === "number") {
      if (!mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, number: "Please enter a valid Phone Number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, number: null }));
      }
    } else if (id === "email") {
      if (!emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, email: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, email: null }));
      }
    } else if (id === "contactNumber") {
      if (!mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, contactNumber: "Please enter a valid Phone Number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, contactNumber: null }));
      }
    } else if (id === "contactEmail") {
      if (!emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, contactEmail: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, contactEmail: null }));
      }
    } else if (id === "manufacturingYear") {
      if (!yearRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: "Please enter a valid year" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: null }));
      }
    }
  };

  const editCustomerForm = () => (
    <Box component="form" onSubmit={editHandleSubmit(submitEditForm)} noValidate id="editCustomerForm">
      <Stack rowGap={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={customerDataById?.name || ""}
            id="customerName"
            label="Customer Name"
            name="customerName"
            autoFocus
            {...editFormRegister("customerName")}
            error={Boolean(validationErrors.name)}
            helperText={validationErrors.name ? validationErrors.name : ""}
            onChange={inputChangeHandler}
          />
          <Controller
            control={control}
            name="state"
            defaultValue={indianStateDropdownOptions.find((item) => item.label == customerDataById?.state) || ""}
            rules={{
              required: "required field",
            }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                required
                fullWidth
                style={{ marginTop: 8 }}
                options={indianStateDropdownOptions}
                defaultValue={indianStateDropdownOptions.find((item) => item.label == customerDataById?.state) || ""}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label == value.label}
                onChange={(event, item) => onChange(item)}
                renderInput={(params) => <TextField {...params} label="State" />}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            margin="normal"
            defaultValue={customerDataById?.telephoneNumber || ""}
            fullWidth
            id="number"
            label="Phone Number"
            name="number"
            {...editFormRegister("number")}
            error={Boolean(validationErrors.number)}
            helperText={validationErrors.number ? validationErrors.number : ""}
            onChange={inputChangeHandler}
            style={{ marginTop: 6 }}
          />
          <TextField
            margin="normal"
            defaultValue={customerDataById?.emailAddress || ""}
            fullWidth
            id="email"
            label="Email"
            name="email"
            {...editFormRegister("email")}
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email ? validationErrors.email : ""}
            onChange={inputChangeHandler}
          />
          <TextField
            margin="normal"
            fullWidth
            defaultValue={customerDataById?.gstNumber || ""}
            id="gstNumber"
            label="Gst Number"
            name="gstNumber"
            {...editFormRegister("gstNumber")}
          />
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center"  position="relative">         
  
          <TextField
            margin="normal"
            defaultValue={customerDataById?.billingAddress || ""}
            fullWidth
            id="billingAddress"
            label="Billing Address"
            name="billingAddress"
            {...editFormRegister("billingAddress")}
            inputProps={{ maxLength: 250 }}
            style={{ marginTop: 6 }}
            multiline
            maxRows={10}
            onChange={inputChangeHandler}
          />
          {isBillingAddressUpdated && (
            <div
              style={{
                position: "absolute",
                bottom: "calc(100% - 8px)",
                left: "625px",
                fontSize: "0.875rem",
                padding: "0.25rem 0.5rem",
              }}
            >
              <span style={{ color: "black" }}> characters: </span>
              <span style={{ color: "red" }}>{billingCharsRemaining}</span>
            </div>
          )}       

          <TextField
            margin="normal"
            fullWidth
            defaultValue={customerDataById?.shippingAddress || ""}
            id="shippingAddress"
            label="Shipping Address"
            name="shippingAddress"
            {...editFormRegister("shippingAddress")}
            inputProps={{ maxLength: 250 }}
            multiline
            maxRows={10}
            sx={{ minWidth: 540, mr: 4 }}
            onChange={inputChangeHandler}
          />
          {isShippingAddressUpdated && (
            <div
              style={{
                position: "absolute",
                bottom: "calc(100% - 8px)",
                right: "16px",
                fontSize: "0.875rem",
                padding: "0.25rem 0.5rem",
              }}
            >
              <span style={{ color: "black" }}> characters: </span>
              <span style={{ color: "red" }}>{shippingCharsRemaining}</span>
            </div>
          )}
        </Stack>
        <Stack style={{ flexDirection: "row", justifyContent: "center" }}>
          <Button
            type="button"
            variant="contained"
            sx={{ height: "self", marginRight: 5, width: 100 }}
            onClick={() => {
              setEditCustomer(null);
            }}
          >
            Close
          </Button>
          <Button type="submit" variant="contained" sx={{ height: "self", width: 100 }}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitAddEditMachineForm = async (data) => {
    if (selectedModel?.id && data.maintenanceCode.length > 0 && data.serialNumber.length > 0 && !validationErrors.manufacturingYear) {
      let collection = {};
      if (selectedMachine) collection.machineId = selectedMachine.id;
      collection.customerId = customerDataById.id;
      collection.modelId = selectedModel.id;
      collection.serialNumber = data.serialNumber;
      collection.maintenanceCode = data.maintenanceCode;
      collection.manufacturingYear = data.manufacturingYear;
      if (machineLocation) collection.location = machineLocation;
      collection.machineModel = selectedModel.id;
      if (amcExpiryDate) collection.amcExpiryDate = amcExpiryDate;
      else if (warrantyExpiryDate) collection.warrantyExpiryDate = warrantyExpiryDate;
      if (selectedMachine) {
        dispatch(addMachineToCustomers(collection)).then((res) => {
          dispatch(readMachinesByCustomerId(id)).then((res) => {});
          resetMachineInput(null);
          toast.success(` Machine Updated `, { autoClose: 1000 });
        });
      } else {
        dispatch(addMachineToCustomers(collection)).then((res) => {
          dispatch(readMachinesByCustomerId(id)).then((res) => {});
          resetMachineInput(null);
          toast.success(` Machine Added `, { autoClose: 1000 });
        });
      }
      setSelectedMachine(null);
      setSelectedModel(null);
      handleCloseEditMachineModal();
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };
  const deleteFunction = async () => {
    if (deleteMachineModel) {
      await dispatch(deleteMachineFromCustomer(deleteMachineModel)).then((res) => {
        dispatch(readMachinesByCustomerId(id)).then((res) => {});
        dispatch(readCustomerById(id)).then((res) => {});
        resetMachineInput(null);
        toast.success(`Machine is deleted`, { autoClose: 1000 });
      });
    } else if (deleteCustomerContact) {
      await dispatch(deleteContactById(deleteCustomerContact));
      reset(null);
      dispatch(readCustomerById(id));
      toast.success(`Contact is deleted`, { autoClose: 1000 });
      setDeleteCustomerContact(null);
    } else if (deleteLocation) {
      dispatch(deleteLocationById({ customerId: id, locationId: deleteLocation.id })).then((res) => {
        dispatch(readCustomerById(id)).then((res) => {});
      });
      resetLocationInput(null);
      toast.success(`Location is deleted`, { autoClose: 1000 });
      setDeleteLocation(null);
    }
    setDeleteModal(false);
  };

  const addEditMachineForm = () => (
    <Box
      component="form"
      onSubmit={addEditMachineHandleSubmit(submitAddEditMachineForm)}
      noValidate
      id="addMachineForm"
      sx={{ minWidth: 500, margin: "0 auto" }} // Adjust the width as needed
    >
      <Stack rowGap={2}>
        <Autocomplete
          disablePortal
          required
          fullWidth
          name="machineModel"
          id="machine model"
          options={machineModels}
          getOptionLabel={(option) => option.model}
          isOptionEqualToValue={(option, value) => option.model == value.model}
          defaultValue={machineModels.find((item) => item.model == selectedMachine?.model) || null}
          onChange={(event, item) => setSelectedModel(item)}
          // sx={{ width: 500, height: 50 }}
          renderInput={(params) => <TextField {...params} label="Machine model*" />}
        />
        <TextField
          margin="normal"
          disabled
          value={selectedModel?.manufacturer || selectedMachine?.manufacturer || ""}
          defaultValue={selectedModel?.manufacturer || selectedMachine?.manufacturer || ""}
          id="manufacturer"
          label="Manufacturer"
          name="manufacturer"
          // autoFocus
          {...addEditMachineFormRegister("manufacturer")}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedMachine?.serialNumber || ""}
          id="serialNumber"
          label="Serial number*"
          name="serialNumber"
          InputProps={{
            inputProps: { maxLength: 250 },
          }}
          // autoFocus
          {...addEditMachineFormRegister("serialNumber")}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedMachine?.maintenanceCode || ""}
          id="maintenanceCode"
          label="Machine Code"
          name="maintenanceCode"
          InputProps={{
            inputProps: { maxLength: 250 },
          }}
          // autoFocus
          {...addEditMachineFormRegister("maintenanceCode")}
        />
        <Autocomplete
          disablePortal
          required
          fullWidth
          name="machineLocation"
          id="machineLocation"
          options={machineLocations}
          getOptionLabel={(option) => option.location}
          isOptionEqualToValue={(option, value) => option.location == value.location}
          defaultValue={machineLocations.find((item) => item.id == selectedMachine?.info?.location?.id) || null}
          onChange={(e, newValue) => setMachineLocation(newValue)}
          // sx={{ width: 500, height: 50 }}
          renderInput={(params) => <TextField {...params} label="Location" />}
        />
        <TextField
          margin="normal"
          fullWidth
          defaultValue={selectedMachine?.manufacturingYear || ""}
          id="manufacturingYear"
          label="Manufacturing year"
          name="manufacturingYear"
          InputProps={{
            inputProps: { maxLength: 4 },
          }}
          // autoFocus
          {...addEditMachineFormRegister("manufacturingYear")}
          error={Boolean(validationErrors.manufacturingYear)}
          helperText={validationErrors.manufacturingYear ? validationErrors.manufacturingYear : ""}
          onChange={inputChangeHandler}
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            minDate={moment(new Date())}
            sx={{
              flex: 1,
              "& .Mui-error": {
                color: "black",
              },
              "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C8C8C8",
                borderBlockColor: "#C8C8C8",
                color: "black",
              },
              "& .MuiInputLabel-root": {
                color: "#888888",
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "black",
              },
            }}
            label="Amc"
            format={"DD MM YYYY"}
            value={moment(selectedMachine?.amcExpiryDate || "")}
            // autoFocus
            onChange={(newValue) => setamcExpiryDate(newValue)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            minDate={moment(new Date())}
            sx={{
              flex: 1,
              "& .Mui-error": {
                color: "black",
              },
              "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C8C8C8",
                borderBlockColor: "#C8C8C8",
                color: "black",
              },
              "& .MuiInputLabel-root": {
                color: "#888888",
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "black",
              },
            }}
            label="Warranty"
            format={"DD MM YYYY"}
            value={moment(selectedMachine?.warrantyExpiryDate || "")}
            // autoFocus
            onChange={(newValue) => setwarrantyExpiryDate(newValue)}
          />
        </LocalizationProvider>
        <Stack flexDirection={"row"} sx={{ justifyContent: "center", gap: 2 }}>
          <Button onClick={() => handleCloseEditMachineModal()} fullWidth variant="contained" sx={{ height: 40, mr: 4 }}>
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ height: 40 }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitEditForm = async (data) => {
    if (!validationErrors.name && !validationErrors.number && !validationErrors.email) {
      await dispatch(
        updateLead({
          state: data.state.label,
          id: customerDataById.id,
          telephoneNumber: data.number,
          emailAddress: data.email,
          name: data.customerName,
          billingAddress: data.billingAddress,
          shippingAddress: data.shippingAddress,
          gstNumber: data.gstNumber,
        })
      ).then(() => dispatch(readCustomerById(id)));

      toast.success(`${data.customerName} customer Updated`, { autoClose: 1000 });
      dispatch(readAllCustomersByState({ indianStateCode: selectedIndianState.value }));
      setEditCustomer(null);
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };

  const addEditLocationForm = () => (
    <Box
      component="form"
      onSubmit={addEditLocationHandleSubmit(submitAddEditLocationForm)}
      noValidate
      id="addMachineForm"
      sx={{ minWidth: 500, margin: "0 auto" }} // Adjust the width as needed
    >
      <Stack rowGap={2}>
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedLocation?.location || ""}
          id="location"
          label="Location*"
          name="location"
          InputProps={{
            inputProps: { maxLength: 250 },
          }}
          // autoFocus
          {...addEditLocationFormRegister("location")}
        />
        <Stack flexDirection={"row"} sx={{ justifyContent: "center", gap: 2 }}>
          <Button onClick={() => handleCloseEditLocationModal()} fullWidth variant="contained" sx={{ height: 40, mr: 4 }}>
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ height: 40 }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitAddEditLocationForm = async (data) => {
    if (data?.location) {
      let collection = {};
      if (selectedLocation) {
        collection.id = selectedLocation.id;
        const isLocationExist = machineLocations.find(
          (item) => item.id !== selectedLocation.id && item.location.toLowerCase() == data.location.toLowerCase()
        );
        if (isLocationExist) return toast.warn("Location with same name exist!");
      } else {
        const isLocationExist = machineLocations.find((item) => item.location.toLowerCase() == data.location.toLowerCase());
        if (isLocationExist) return toast.warn("Location with same name exist!");
      }
      collection.location = data.location;
      collection.customerId = id;
      dispatch(addLocationsToCustomers(collection)).then((res) => {
        dispatch(readCustomerById(id)).then((res) => {});
        resetLocationInput(null);
        toast.success(` Location ${selectedLocation?.id ? "Updated" : "Added"} `, { autoClose: 1000 });
      });
      setSelectedLocation(null);
      handleCloseEditLocationModal();
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };
  const addEditContactForm = () => (
    <Box component="form" onSubmit={addEditHandleSubmit(submitAddEditContactForm)} noValidate id="addContactForm" sx={{ minWidth: 400 }}>
      <Stack rowGap={1}>
        <TextField
          margin="normal"
          //required
          defaultValue={selectedContact?.name || ""}
          fullWidth
          id="contactName"
          label="Person Name*"
          name="contactName"
          textTransform="capitalize"
          // autoFocus
          {...addEditContactFormRegister("contactName")}
          error={Boolean(validationErrors.contactName)}
          helperText={validationErrors.contactName ? validationErrors.contactName : ""}
          onChange={inputChangeHandler}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedContact?.mobileNumber || ""}
          id="contactNumber"
          label="Phone Number"
          name="contactNumber"
          {...addEditContactFormRegister("contactNumber")}
          error={Boolean(validationErrors.contactNumber)}
          helperText={validationErrors.contactNumber ? validationErrors.contactNumber : ""}
          onChange={inputChangeHandler}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedContact?.email || ""}
          id="contactEmail"
          label="Email"
          name="contactEmail"
          {...addEditContactFormRegister("contactEmail")}
          error={Boolean(validationErrors.contactEmail)}
          helperText={validationErrors.contactEmail ? validationErrors.contactEmail : ""}
          onChange={inputChangeHandler}
        />
        <Stack flexDirection={"row"} justifyContent={"center"} gap={2}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 40, mr: 4 }}
            onClick={() => {
              handleCloseEditContactModal();
            }}
          >
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ height: 40 }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  const submitAddEditContactForm = async (data) => {
    if (data.contactName && !validationErrors.contactEmail && !validationErrors.contactNumber) {
      setSelectedContact(null);
      const dispatchAction = selectedContact ? updateContact : addContactToLead;
      await dispatch(
        dispatchAction({
          id: selectedContact ? selectedContact.id : id,
          mobileNumber: data.contactNumber,
          email: data.contactEmail,
          name: data.contactName,
        })
      ).then(() => {
        reset(null);
        const actionVerb = selectedContact ? "Updated" : "Added";
        toast.success(`Contact ${actionVerb}`, { autoClose: 1000 });
        dispatch(readCustomerById(id));
      });
      handleCloseEditContactModal();
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };
  const handleEdit = (machine) => {
    setSelectedModel(machineModels.find((item) => item.model === machine.model));
    setSelectedMachine(machine);
    setAddEditMachineModal(true);
  };
  const generateQRCodeImage = async (data) => {
    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;
    const response = await fetch(qrCodeApiUrl);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };
  const handleQRCodeClick = async (params) => {
    try {
      const machineData = machinesByCustomerId?.data.find((item) => item.id === params.id);
      if (machineData) {
        const data = `
                Model: ${machineData.model}
                Serial number: ${machineData.serialNumber}
                Maintenance Code: ${machineData?.maintenanceCode || ""}
                Location: ${machineData?.info?.location?.location || ""}
                Manufacturer: ${machineData?.manufacturer || ""}
                Manufacturing Year: ${machineData.manufacturingYear}
                AMC: ${machineData.amcExpiryDate ? moment(machineData.amcExpiryDate).format("DD MMM YYYY") : ""}
                Warranty: ${machineData.warrantyExpiryDate ? moment(machineData.warrantyExpiryDate).format("DD MMM YYYY") : ""}
                CustomerId: ${id}
                customerName: ${customerDataById?.name || ""}
                state: ${customerDataById?.state || ""}
                machineId: ${machineData.id}
            `;
        const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

        const response = await fetch(qrCodeApiUrl);
        if (!response.ok) {
          console.error("Failed to generate QR code.");
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        const custName = customerDataById?.name || "";
        downloadLink.download = `${custName}_${machineData?.maintenanceCode || ""}_qr_code_machine_.png`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    doc.setFontSize(11);
    const machineList = machinesByCustomerId.data;
    const qrCodeWidth = 46.2;
    const qrCodeHeight = 46.2;
    const qrCodesPerRow = 3;
    const rowsPerPage = 4;
    const marginLeft = 13;
    const marginTop = 10;
    const spacingX = 67;
    const spacingY = 70;
    const totalQRCodeCount = machineList.length;
    let currentQRCodeCount = 0;
    setHideProgress(false);
    for (let i = 0; i < machineList.length; i += qrCodesPerRow * rowsPerPage) {
      if (i !== 0) {
        doc.addPage();
      }

      for (let row = 0; row < rowsPerPage; row++) {
        for (let col = 0; col < qrCodesPerRow; col++) {
          const currentIndex = i + row * qrCodesPerRow + col;
          if (currentIndex < machineList.length) {
            const machine = machineList[currentIndex];
            const formattedData = `
                Model: ${machine.model}
                Serial number: ${machine.serialNumber}
                Maintenance Code: ${machine?.maintenanceCode || ""}
                Location: ${machine?.info?.location?.location || ""}
                Manufacturer: ${machine?.manufacturer || ""}
                Manufacturing Year: ${machine.manufacturingYear}
                AMC: ${machine.amcExpiryDate}
                Warranty: ${machine.warrantyExpiryDate}
                CustomerId: ${id}
                customerName: ${customerDataById?.name || ""}
                state: ${customerDataById?.state || ""}
                machineId: ${machine.id}
              `;

            const qrCodeImageUrl = await generateQRCodeImage(formattedData);
            const x = marginLeft + col * spacingX;
            const y = marginTop + row * spacingY;
            doc.text(`QR Code for Service Ticket\n`, x, y - 1);
            doc.setFontSize(9);
            doc.text(`${machine.model.toUpperCase()}\n${machine.serialNumber.toUpperCase()}\n`, x, y + qrCodeHeight + 5);
            doc.addImage(qrCodeImageUrl, "PNG", x, y, qrCodeWidth, qrCodeHeight);
            doc.setFontSize(11);

            currentQRCodeCount++;
            const progress = (currentQRCodeCount / totalQRCodeCount) * 100;
            setDownloadProgress(progress);
          }
        }
      }
    }

    const custName = customerDataById?.name || "";

    doc.save(`${custName}_qr_codes_machines_${moment().format("DD-MM-YYYY_HH:mm")}.pdf`);

    setDownloadProgress(100);
    setHideProgress(true);
  };

  const deleteContactForm = async (data) => {
    await dispatch(deleteContactById(data));
    dispatch(readCustomerById(id));
    toast.success(`Contact is deleted`, { autoClose: 1000 });
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="Download QR">
          <Button onClick={() => handleQRCodeClick(params?.row)}>
            <QRCodeIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Edit">
          <Button sx={{ cursor: "pointer", color: "#5D8AA8" }} onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </Button>
        </Tooltip>
      </strong>
    );
  };

  const renderContactsActions = (params) => {
    return (
      <strong>
        <Tooltip title="Edit">
          <Button
            sx={{ cursor: "pointer", color: "#5D8AA8" }}
            onClick={() => {
              setSelectedContact(params.row);
              setEditContactModal(true);
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            onClick={() => {
              setSelectedContact(null);
              setDeleteCustomerContact(params.row);
              setDeleteModal(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </strong>
    );
  };
  const renderLocationsButton = (params) => {
    return (
      <strong>
        <Tooltip title="Edit">
          <Button
            sx={{ cursor: "pointer", color: "#5D8AA8" }}
            onClick={() => {
              setSelectedLocation(params.row);
              setAddEditLocationModal(true);
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            onClick={() => {
              setSelectedContact(null);
              setDeleteLocation(params.row);
              setDeleteModal(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </strong>
    );
  };
  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const columns = [
    { field: "manufacturer", headerName: "Manufacturer", width: 350, flex: 0.4 },
    { field: "model", headerName: "Model", width: 190, flex: 0.4 },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 160,
      flex: 0.5,
      sortable: true,
      sortComparator: (v1, v2) => {
        return v1.localeCompare(v2, undefined, { numeric: true });
      },
    },
    {
      field: "maintenanceCode",
      headerName: "Machine Code",
      width: 160,
      flex: 0.5,
      sortable: true,
      sortComparator: (v1, v2) => {
        return v1.localeCompare(v2, undefined, { numeric: true });
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 190,
      flex: 0.4,
      valueGetter: ({ row }) => {
        return row?.info?.location?.location || "-";
      },
    },
    {
      field: "amcExpiryDate",
      headerName: "AMC",
      width: 150,
      flex: 0.5,
      sortable: true,
      sortComparator: dateComparator,
    },
    {
      field: "warrantyExpiryDate",
      headerName: "Warranty",
      width: 150,
      flex: 0.5,
      sortable: true,
      sortComparator: dateComparator,
    },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const locationColumns = [
    { field: "location", headerName: "Location", width: 350, flex: 0.4 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderLocationsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];
  const contactsColumns = [
    { field: "name", headerName: "Name", width: 190, flex: 0.4 },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 160,
      flex: 0.5,
      sortable: true,
      sortComparator: (v1, v2) => {
        return v1.localeCompare(v2, undefined, { numeric: true });
      },
    },
    { field: "email", headerName: "Email", width: 190, flex: 0.4 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderContactsActions,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];
  const customeStyle = {
    flex: 1,
    padding: 2,
    mb: 1,
    border: "1px solid #ddd",
    borderRadius: "2px",
    backgroundColor: "#fff",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  };
  const handleSearchChange = (event) => setSearchTerm(event.target.value);
  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchContactTerm("");
  };
  const handleCloseEditContactModal = () => {
    setSelectedContact(null);
    setEditContactModal(false);
  };
  const handleCloseEditMachineModal = () => {
    setSelectedModel(null);
    setSelectedMachine(null);
    setAddEditMachineModal(false);
  };
  const handleCloseEditLocationModal = () => {
    setSelectedLocation(null);
    setAddEditLocationModal(false);
  };

  return (
    <div>
      <Stack sx={{ mb: 1 }}>
        <Card sx={{ p: 3, boxShadow: "none" }}>
          {editCustomer ? (
            <>{editCustomerForm()}</>
          ) : (
            <Stack>
              <Stack sx={[customeStyle]}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" fontWeight={600}>
                    {customerDataById?.name || ""}
                  </Typography>
                  <Button sx={{ height: "self" }} variant="contained" onClick={() => setEditCustomer(true)}>
                    {editCustomer ? "Save" : "Edit"}
                  </Button>
                </Stack>
                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>State:</span> {customerDataById?.state || ""}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Phone Number:</span> {customerDataById?.telephoneNumber || ""}
                </Typography>

                <Typography sx={{ display: "inline" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Email:</span> {customerDataById?.emailAddress || ""}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Billing Address:</span> {customerDataById?.billingAddress || ""}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Shipping Address:</span> {customerDataById?.shippingAddress || ""}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Gst Number:</span> {customerDataById?.gstNumber || ""}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Card>
      </Stack>
      <div>
        <Paper sx={{ mx: 3 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <Typography fontWeight={"bold"}>Contacts</Typography>
            </AccordionSummary>
            <AccordionActions sx={{ justifyContent: "space-between", direction: "row", mx: 1 }}>
              <OutlinedInput
                id="outlined-adornment-password"
                value={searchContactTerm}
                onChange={(event) => setSearchContactTerm(event.target.value)}
                placeholder="Search..."
                endAdornment={
                  <InputAdornment position="end">
                    {searchContactTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                }
                sx={{ minWidth: 350, maxWidth: 600, height: 35, marginLeft: "2px" }}
              />
              <div>
                <Button variant="contained" onClick={() => setEditContactModal(true)}>
                  ADD
                </Button>
              </div>
            </AccordionActions>
            <AccordionDetails>
              <Box sx={{ height: 450, paddingTop: 1 }}>
                <DataGrid
                  // checkboxSelection={true}
                  rows={
                    customerDataById?.contacts && customerDataById?.contacts.length
                      ? customerDataById?.contacts.filter((item) =>
                          Object.values(item).join(" ").toLowerCase().includes(searchContactTerm.toLowerCase())
                        )
                      : []
                  }
                  columns={contactsColumns}
                  pageSize={5}
                  localeText={{ noRowsLabel: "No Data." }}
                  style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
                  onSelectRow={selectedContact}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
        <Paper sx={{ mx: 3, my: 1 }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <Typography fontWeight={"bold"}>Machines</Typography>
            </AccordionSummary>
            <AccordionActions sx={{ justifyContent: "space-between", direction: "row", mx: 1 }}>
              <OutlinedInput
                id="outlined-adornment-password"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Search..."
                endAdornment={
                  <InputAdornment position="end">
                    {searchTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                }
                sx={{ minWidth: 350, maxWidth: 600, height: 35, marginLeft: "2px" }}
              />
              <div>
                <Stack direction={"row"}>
                  <Button
                    startIcon={<DownloadIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={generatePDF}
                    disabled={!hideProgress}
                  >
                    Download QR
                  </Button>
                  {downloadProgress !== null && !hideProgress && (
                    <Box>
                      <LinearProgress variant="determinate" value={downloadProgress} />
                      <Typography variant="body2" color="textSecondary">{`${downloadProgress.toFixed(2)}%`}</Typography>
                    </Box>
                  )}
                  <Button variant="contained" sx={{ ml: 3 }} onClick={() => setAddEditMachineModal(true)}>
                    ADD
                  </Button>
                </Stack>
              </div>
            </AccordionActions>
            <AccordionDetails>
              <Box sx={{ height: 650, paddingTop: 1 }}>
                <DataGrid
                  // checkboxSelection={true}
                  rows={machinesByCustomerId.data.filter((item) =>
                    Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase())
                  )}
                  columns={columns}
                  pageSize={5}
                  localeText={{ noRowsLabel: "No Data." }}
                  style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
                  onSelectRow={selectedMachine}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
        <Paper sx={{ mx: 3, mb: 5 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <Typography fontWeight={"bold"}>Locations</Typography>
            </AccordionSummary>
            <AccordionActions sx={{ justifyContent: "space-between", direction: "row", mx: 1 }}>
              <OutlinedInput
                id="outlined-adornment-password"
                value={searchLocationTerm}
                onChange={(event) => setSearchLocationTerm(event.target.value)}
                placeholder="Search..."
                endAdornment={
                  <InputAdornment position="end">
                    {searchLocationTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                }
                sx={{ minWidth: 350, maxWidth: 600, height: 35, marginLeft: "2px" }}
              />
              <div>
                <Stack direction={"row"}>
                  <Button variant="contained" sx={{ ml: 3 }} onClick={() => setAddEditLocationModal(true)}>
                    ADD
                  </Button>
                </Stack>
              </div>
            </AccordionActions>
            <AccordionDetails>
              <Box sx={{ height: 650, paddingTop: 1 }}>
                <DataGrid
                  // checkboxSelection={true}
                  rows={machineLocations.filter((item) =>
                    Object.values(item).join(" ").toLowerCase().includes(searchLocationTerm.toLowerCase())
                  )}
                  columns={locationColumns}
                  pageSize={5}
                  localeText={{ noRowsLabel: "No Data." }}
                  style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
                  onSelectRow={selectedMachine}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </div>
      <div>
        <Dialog open={addEditLocationModal} alignSelf="center" onClose={handleCloseEditLocationModal}>
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {`${selectedLocation ? "Edit" : "Add"} Location`}
            <IconButton onClick={() => handleCloseEditLocationModal()} style={{ position: "absolute", top: 13, right: 0 }}>
              <CloseIcon />
              <Typography sx={{ mr: 2 }}>Close</Typography>
            </IconButton>
          </DialogTitle>
          <Box sx={{ px: 4, mb: 4 }}>{addEditLocationForm()}</Box>
        </Dialog>
      </div>
      <div>
        <Dialog open={editMachineModal} alignSelf="center" onClose={handleCloseEditMachineModal}>
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {`${selectedMachine ? "Edit" : "Add"} Machine`}
            <IconButton onClick={() => handleCloseEditMachineModal()} style={{ position: "absolute", top: 13, right: 0 }}>
              <CloseIcon />
              <Typography sx={{ mr: 2 }}>Close</Typography>
            </IconButton>
          </DialogTitle>
          <Box sx={{ px: 4, mb: 4 }}>{addEditMachineForm()}</Box>
        </Dialog>
      </div>
      <div>
        <Dialog open={editContactModal} alignSelf="center" onClose={handleCloseEditContactModal}>
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {`${selectedContact ? "Edit" : "Add"} Contact`}
            <IconButton
              onClick={() => {
                handleCloseEditContactModal();
              }}
              style={{ position: "absolute", top: 13, right: 0 }}
            >
              <CloseIcon />
              <Typography sx={{ mr: 2 }}>Close</Typography>
            </IconButton>
          </DialogTitle>
          <Box sx={{ px: 4, mb: 4 }}>{addEditContactForm()}</Box>
        </Dialog>
      </div>
      <div>
        <Dialog open={deleteModal} alignSelf="center">
          <Alert
            open={deleteModal}
            // onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div id="alert-dialog-title">Confirm Action</div>
            <div id="alert-dialog-description">
              Do you want to delete{" "}
              {deleteMachineModel
                ? `this machine (${deleteMachineModel.model})`
                : deleteCustomerContact
                ? `this contact (${deleteCustomerContact.name})`
                : deleteLocation
                ? `this location (${deleteLocation?.location || ""})`
                : null}
              ?
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => setDeleteModal(false)}>No</Button>
              <Button onClick={() => deleteFunction()}>Yes</Button>
            </div>
          </Alert>
        </Dialog>
      </div>
    </div>
  );
};

export default CustomerContact;
