import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../app/api";

export const getServiceTicketsForTheLastXDays = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (days, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/serviceapi/getServiceTicketsForTheLastXDays?days=${days}`, {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusList = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/ getServiceTicketsByStatusList`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomersWithServiceTickets = createAsyncThunk(
  "customers/getCustomersWithServiceTickets",
  async (_, { rejectWithValue }) => {
    try {
      const st = new Date().getSeconds();
      const { data } = await api.get(`/serviceapi/getCustomersWithServiceTickets`);
      if (data) {
        const et = new Date().getSeconds();
        const modified = [...data].sort((a, b) => a.customerName.localeCompare(b.customerName));
        console.log("modified--", et - st);
        return modified;
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const readServiceTicket = createAsyncThunk("serviceTickets/readServiceTicket", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const response = await api.get(`serviceapi/readServiceTicket?id=${serviceTicketId}`);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const readServiceTicketMessagesByServiceTicketId = createAsyncThunk(
  "serviceTickets/readServiceTicketMessagesByServiceTicketId",
  async (serviceTicketId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/readServiceTicketMessagesByServiceTicketId?id=${serviceTicketId}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createServiceTicket = createAsyncThunk("servicetickets/createServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/createServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const updateServiceTicket = createAsyncThunk("servicetickets/updateServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/updateServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const addTextMessageToServiceTicket = createAsyncThunk(
  "servicetickets/addTextMessageToServiceTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("/serviceapi/addTextMessageToServiceTicket", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const readServiceTimeLog = createAsyncThunk("servicetickets/readServiceTimeLog", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`serviceapi/readServiceTimeLog?id=${serviceTicketId}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getSvcTktDetailsForWhatsappMessage = createAsyncThunk(
  "servicetickets/getSvcTktDetailsForWhatsappMessage",
  async (serviceTicketId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/getSvcTktDetailsForWhatsappMessage?serviceTicketId=${serviceTicketId}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByUserId = createAsyncThunk(
  "servicetickets/getServiceTicketsByUserId",
  async (payload, { rejectWithValue }) => {
    try {
      const url = `/serviceapi/getServiceTicketsByUserId?userId=${payload.userId}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
      const { data } = await api.get(url);
      if (data) return data.sort((a, b) => b.id - a.id);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const readReportPathByServiceTicketId = createAsyncThunk(
  "servicetickets/readReportPathByServiceTicketId",
  async (serviceTicketId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/readReportPathByServiceTicketId?serviceTicketId=${serviceTicketId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getServiceTicketsForNotification = createAsyncThunk(
  "serviceTickets/getServiceTicketsForNotification",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/notifyEngineer`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const readPaidCompletedServiceTicket = createAsyncThunk(
  "servicetickets/readPaidCompletedServiceTickets",
  async (_, { rejectWithValue }) => {
    console.log("inside action");

    try {
      const { data } = await api.get(`serviceapi/readPaidCompletedServiceTickets`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const requestPaidTicketInvoiceFromAccounts = createAsyncThunk(
  "servicetickets/requestPaidTicketInvoiceFromAccounts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("serviceapi/requestPaidTicketInvoiceFromAccounts", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addImageOrVideoToServiceTicket = createAsyncThunk(
  "servicetickets/addImageOrVideoToServiceTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("serviceapi/addImageOrVideoToServiceTicket", payload.formData, {
        onUploadProgress: payload.progressCallback,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsForSvcCoordinator = createAsyncThunk(
  "servicetickets/getServiceTicketsForSvcCoordinator",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("serviceapi/getServiceTicketsForSvcCoordinator", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
