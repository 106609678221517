import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Button, Stack, InputAdornment, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getServiceTicketsByUserId, getServiceTicketsForSvcCoordinator } from "../../features/servicetickets/actions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { readAsyncStorageValues } from "../../features/common/actions";
import { getAppUsers } from "../../features/users/actions";
const MyTickets = () => {
  const dispatch = useDispatch();
  const { allSvcTickets, serviceTicketsByUserId } = useSelector((state) => state.serviceTickets);
  const { appUsersList } = useSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(2, "months").toDate());
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) dispatch(readAsyncStorageValues());
  }, [dispatch, userInfo]);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAppUsers());
  }, [dispatch]);
  useEffect(() => {
    if (asyncStorageValues && asyncStorageValues.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const serviceUsers = appUsersList.data
        .filter((obj) => obj.userRole === roles.ROLE_SERVICE_COORDINATOR && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));
      const loggedInUser = serviceUsers.find((user) => user.userName === userInfo.username);

      if (loggedInUser) {
        setUserId(loggedInUser.userId);
      } else {
        setUserId(null);
      }
    }
  }, [appUsersList, asyncStorageValues, userCurrentRole, userInfo]);

  useEffect(() => {
    if (userId) {
      const payload = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        userId: userId,
      };
      dispatch(getServiceTicketsByUserId(payload));
      console.log("pay", payload);
    }
  }, [dispatch, userId, startDate, endDate]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        {/* <Tooltip title="View">
                    <Button
                        startIcon={<ZoomInIcon />}
                        color="primary"
                        size="large"
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                            navigate("/all-service-ticket-view/" + params.row.serviceTicketId);
                        }}
                    />
                </Tooltip> */}
      </strong>
    );
  };
  const customers = asyncStorageValues?.customers || [];
  const customerName = (customerId) => {
    const customer = customers.find((customer) => customer.id === customerId);
    return customer ? customer.customerName : "N/A";
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.1,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        const dateCreated = params.row?.dateCreated;
        if (dateCreated) {
          return moment(dateCreated).format("DD MMM YYYY");
        }
      },
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.1,
      valueGetter: (params) => {
        const closedDate = params.row?.closedDate;
        if (closedDate) {
          return moment(closedDate).format("DD MMM YYYY");
        }
      },
      sortComparator: dateComparator,
    },
    { field: "customerName", headerName: "Customer Name", flex: 0.3 },
    { field: "ticketCategory", headerName: "Category", flex: 0.3 },
    { field: "status", headerName: "Status", flex: 0.2 },
    // {
    //   field: "col6",
    //   headerName: "",
    //   flex: 0.2,
    //   renderCell: renderDetailsButton,
    //   disableClickEventBubbling: false,
    //   sortable: false,
    //   filterable: false,
    // },
  ];
  const filteredData = serviceTicketsByUserId.data.filter((ticket) => {
    return !searchTerm || Object.values(ticket).join(" ").toLowerCase().includes(searchTerm.toLowerCase());
  });
  const rows = filteredData.map((ticket) => ({
    id: ticket.id,
    dateCreated: ticket.dateCreated,
    closedDate: ticket.closeDate,
    customerName: customerName(ticket.customer?.id),
    ticketCategory: ticket.ticketCategory,
    status: ticket.status,
  }));
  const handleStartDateChange = (date) => {
    setStartDate(date.toDate());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date.toDate());
  };

  return (
    <Stack justifyContent="center" sx={{ mx: 2, my: 5 }}>
      <Stack direction={"row"} ml={3} alignItems="center" justifyContent={"space-between"}>
        <TextField
          style={{ minWidth: 450 }}
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton edge="end" onClick={handleClearSearch} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Stack direction={"row"}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={moment(startDate)}
              onChange={handleStartDateChange}
              label="Start Date"
              format={"DD MMM YYYY"}
              disableFuture
              sx={{ minWidth: 200, paddingRight: 5 }}
            />
            <DatePicker
              value={moment(endDate)}
              onChange={handleEndDateChange}
              label="End Date"
              format={"DD MMM YYYY"}
              disableFuture
              sx={{ minWidth: 200, paddingRight: 5 }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>

      <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8, paddingRight: "1%", overflow: "auto" }}>
        {loading || serviceTicketsByUserId.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={rows}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default MyTickets;
