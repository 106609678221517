import { createSlice } from "@reduxjs/toolkit";
import { createTemplate, getAllTemplates, updateTemplate, deleteTemplate } from "../templates/actions";

const initialState = {
  loading: false,
  template: [],
  error: null,
  success: false,
  allTemplates: {
    data: [],
    loading: false,
  },
};

const commonSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: {
    [createTemplate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createTemplate.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateTemplate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateTemplate.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getAllTemplates.pending]: (state) => {
      state.allTemplates.loading = true;
      state.error = null;
    },
    [getAllTemplates.fulfilled]: (state, { payload }) => {
      state.allTemplates.loading = false;
      state.allTemplates.data = payload.data;
    },
    [getAllTemplates.rejected]: (state, { payload }) => {
      state.allTemplates.loading = false;
      state.error = payload;
    },
    [deleteTemplate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteTemplate.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteTemplate.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default commonSlice.reducer;
